<style scoped>
.svg-icon-close{
  float: right;
  font-size: xx-small;
  font-weight: 300;
  margin-top: 2px;
}
</style>

<template>
  <div>
    <Modal v-model="isShow" width="1000" footer-hide :styles="{top: '20px'}">
      <Tabs v-if="svgList.length>0" v-model="defaultTab" :animated="false" @on-click="changeTabs">
            <TabPane v-for="(item, index) in svgList" :key="index" :label="stationLabel(index)" :name="'tab'+index">
                <ul style="list-style-type:none;">
                    <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#008100"></a>90天以下未换刊</li>
                    <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#FFEB3A"></a>90-180天未换刊</li>
                    <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#F54236"></a>180天以上未换刊</li>
                </ul>

                <div style="clear:both;"></div>
                <Row>
                  <i-col :span="(showButtonIcons.length>0&&isShowIcons)?22:24">
                    <div v-html="item.svg" name="svgStationHtml" :style="{ height: '680px'  }" class="svg-station-content" :id="svgId + '_' + index"></div>
                  </i-col>
                  <i-col v-if="showButtonIcons.length>0&&isShowIcons" span="2" class="icon-legend">
                    <h3 class="p-b-5">
                      图例<a class="svg-icon-close" @click="isShowIcons=false">隐藏</a>
                    </h3>
                    <div v-for="(icon,index) in showButtonIcons" :key="index">
                      <p class="m-b-5">{{icon.title.substr(0,7)}}</p>
                      <Row v-if="!!icon.mergeHtml" >
                        <i-col span="12"><svg v-html="icon.svg" style="height:20px"></svg></i-col>
                        <i-col span="12"><svg v-html="icon.mergeHtml" style="height:20px"></svg></i-col>
                      </Row>
                      <svg v-else v-html="icon.svg" style="height:20px"></svg>
                    </div>
                  </i-col>
                </Row>
            </TabPane>
        </Tabs>

        <div v-else class="default-content">
            <p style="font-size: xx-large;">抱歉，暂无SVG站点地图</p>
        </div>

      <Spin size="large" fix v-if="spinShow">
          <Icon type="ios-loading" size=18 class="-spin-icon-load"></Icon>
          <div>地图加载中</div>
      </Spin>
    </Modal>
  </div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'
import { newGuid } from '@/utils/mapCommon'

import { getRimStationSvgByStationId } from '@/api/rim/stationgraph'
import { getIcon } from '@/api/draw/svg'

export default {
  props: {
    stationSvgModal: { type: Boolean, required: true },
    stationId: {
      type: [String, Number], required: true
    }
  },
  data () {
    return ({
      isShow: false,
      spinShow: false,

      svgList: [],
      defaultTab: 'tab0',
      svgId: '',
      stationTabLables: [], // 用于标注点位数量

      buttonIcons: [],
      showButtonIcons: [], // 需要显示的ICON清单
      isShowIcons: true,
      existSvgZoom: [],
      buttonIconsSizeLarge: [1, 2, 4, 5, 6, 68, 72, 77, 79, 80, 81, 87, 88],
      buttonIconsSizeSmall: [78]
    })
  },
  methods: {
    initPageData () {
      this.spinShow = true
      this.isShowIcons = true
      this.loadIcons()
      // 获取站点svg条件字段封装
      const postData = { stationIds: this.stationId }
      getRimStationSvgByStationId(postData).then(res => {
        this.svgList = res
        this.stationTabLables = []
        let label
        for (let index = 0; index < res.length; index++) {
          label = (res[index].floor > 0) ? '地上' : '地下'
          label += res[index].floor + '层' + res[index].positionName
          this.stationTabLables.push({
            label: label,
            quantity: 0
          })
        }

        if (this.svgList.length > 0) {
          // this.defaultTab = 'tab0'
          // this.beginSvgPanZoom(this.svgId + '_0')
          this.changeTabs('tab0')
          this.$emit('svg-finished')
        } else {
          this.$emit('svg-finished')
        }
      })
    },
    beginSvgPanZoom (svgId) {
      if (this.existSvgZoom.indexOf(svgId) > -1) {
        return
      } else {
        this.existSvgZoom.push(svgId)
      }

      this.$nextTick(() => {
        const currentSvgElement = document.getElementById(svgId).firstElementChild
        // 设置svg呈现在画板上的尺寸
        currentSvgElement.setAttribute('width', '100%')
        currentSvgElement.setAttribute('height', '100%')

        var curMap = svgPanZoom(currentSvgElement, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          center: true
        }).zoom(1.3)

        if (currentSvgElement.id === '') {
          curMap.zoom(1.5)
          this.addMapMouseEvent(currentSvgElement)
        }
      })
    },
    changeTabs (name) {
      this.defaultTab = name
      const currentSvgId = this.svgId + '_' + name.substr(3)
      this.beginSvgPanZoom(currentSvgId)

      // 确认需要显示的ICON
      this.showButtonIcons = []
      this.chooseIcon = null

      this.$nextTick(() => {
        const gNodeList = document.getElementById(currentSvgId).getElementsByTagName('g')
        if (gNodeList.length > 0) {
          for (let j = 0; j < gNodeList.length; j++) {
            const newEl = gNodeList[j]

            if (newEl.hasAttribute('data-devicemodelid')) {
              // 双面电子屏, 同时不包含301的电子屏
              if (newEl.getAttribute('data-devicemodelid') === '306') {
                if (!this.showButtonIcons.find(x => x.deviceModels === '301')) {
                  this.showButtonIcons.push(
                    this.buttonIcons.find(x => x.deviceModels === '301')
                  )
                }
              } else if (!this.showButtonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid'))) {
                this.showButtonIcons.push(
                  this.buttonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid'))
                )
              }
            }
          }
        }
      })
    },
    choseSpinShow () {
      this.spinShow = false
    },
    loadIcons () {
      getIcon().then(res => {
        this.buttonIcons = res.map((item) => {
          let svgHtml = item.svg
          let svgTitle = item.title
          let mergeHtml = ''
          // 设置部分图标放大比例显示
          if (this.buttonIconsSizeLarge.includes(item.id)) {
            svgHtml = svgHtml.replace('<g>', '<g transform="scale(1.5)">')
          } else if (this.buttonIconsSizeSmall.includes(item.id)) {
            svgHtml = svgHtml.replace('<g>', '<g transform="scale(0.7)">')
          }

          if (item.deviceModels === '114') {
            svgTitle = '柱贴'
            mergeHtml = res.filter(x => x.deviceModels === '114')[1].svg
          } else if (item.deviceModels === '124') {
            svgTitle = '包柱灯箱'
            mergeHtml = res.filter(x => x.deviceModels === '124')[1].svg
          }

          return {
            title: svgTitle,
            svg: svgHtml,
            mergeHtml: mergeHtml,
            deviceModels: item.deviceModels // ICON对应的媒体类型ID
          }
        })
      })
    },
    tagResource (resourceBeanList) {
      const pathNodeList = document.getElementsByTagName('path')
      const gNodeList = document.getElementsByTagName('g')

      if (pathNodeList.length > 0 && resourceBeanList.length > 0) {
        for (let i = 0; i < pathNodeList.length; i++) {
          const el = pathNodeList[i]

          if (el.hasAttribute('data-id')) {
            // 判断灯箱点位是否包含在清单中
            const elementId = el.getAttribute('data-id')
            const resourceBean = resourceBeanList.find(x => x.id === elementId)
            if (resourceBean) {
              // 开始标记颜色
              if (resourceBean.number < 90) {
                el.setAttribute('fill', '#008100') // 绿色
              } else if (resourceBean.number < 180) {
                el.setAttribute('fill', '#FFEB3A') // 黄色
              } else {
                el.setAttribute('fill', '#F54236') // 红色
              }
            }
          }
        }
      }

      // 新版地图使用g
      if (gNodeList.length > 0) {
        for (let j = 0; j < gNodeList.length; j++) {
          const newEl = gNodeList[j]
          if (newEl.hasAttribute('data-id')) {
            // 判断灯箱点位是否包含在清单中
            const elementId = newEl.getAttribute('data-id')
            const resourceBean = resourceBeanList.find(x => x.id === elementId)
            if (resourceBean) {
              // 开始标记颜色
              let fillColor = ''
              if (resourceBean.number < 90) {
                fillColor = '#008100'
                newEl.setAttribute('fill', fillColor) // 绿色
              } else if (resourceBean.number < 180) {
                fillColor = '#FFEB3A'
                newEl.setAttribute('fill', fillColor) // 黄色
              } else {
                fillColor = '#F54236'
                newEl.setAttribute('fill', fillColor) // 红色
              }

              const gChild = newEl.children
              for (let k = 0; k < gChild.length; k++) {
                gChild[k].setAttribute('fill', fillColor) // 红色
              }
            }
          }
        }
      }
    },
    stationLabel (index) {
      return (h) => {
        return h('div', [
          h('span', this.stationTabLables[index].label),
          h('Badge', {
            style: {
              left: '5px',
              top: '-10px'
            },
            props: {
              count: this.stationTabLables[index].quantity
            }
          })
        ])
      }
    },
    addMapMouseEvent (svgElement) { // 添加普通地图的事件
      const svgChidrenElement = svgElement.lastElementChild.lastElementChild.children

      if (svgChidrenElement) {
        for (let index = 0; index < svgElement.lastElementChild.children.length; index++) {
          // 移除title标签， 改标签用于tips
          if (svgElement.lastElementChild.children[index].nodeName === 'title') {
            svgElement.lastElementChild.removeChild(svgElement.lastElementChild.children[index])
          } else if (svgElement.lastElementChild.children[index].children[0].nodeName === 'title') {
            svgElement.lastElementChild.children[index].removeChild(svgElement.lastElementChild.children[index].children[0])
          }
        }

        for (let index = 0; index < svgChidrenElement.length; index++) {
          if (svgChidrenElement[index].nodeName === 'g' && svgChidrenElement[index].hasAttribute('data-id')) {
            // 为媒体元素添加鼠标事件
            const curElement = svgChidrenElement[index]
            const xOffset = 15
            const yOffset = 15
            // curElement.setAttribute('cursor', 'pointer')

            curElement.addEventListener('mouseover', (e) => {
              for (let i = 0; i < curElement.children.length; i++) {
                if (curElement.children[i].nodeName !== 'text' && curElement.children[i].hasAttribute('stroke') && curElement.children[i].getAttribute('stroke') !== 'null') {
                  curElement.children[i].setAttribute('stroke', '#19be6b')
                }
              }

              var divTemp = document.createElement('div')
              var nodes = null
              // 文档片段，一次性append，提高性能
              var fragment = document.createDocumentFragment()
              divTemp.innerHTML = "<div id='screenshot'><p>" + curElement.getAttribute('data-devicemodel') + '</p><p>' + curElement.getAttribute('data-devicecode') + '</p></div>'
              nodes = divTemp.childNodes
              for (var i = 0, length = nodes.length; i < length; i += 1) {
                fragment.appendChild(nodes[i].cloneNode(true))
              }
              svgElement.parentNode.appendChild(fragment)
              // 据说下面这样子世界会更清净
              nodes = null
              fragment = null

              document.getElementById('screenshot').style.top = (e.offsetY + yOffset) + 'px'
              document.getElementById('screenshot').style.left = (e.offsetX + xOffset) + 'px'
            })
            curElement.addEventListener('mouseout', (e) => {
              for (let j = 0; j < curElement.children.length; j++) {
                if (curElement.children[j].nodeName !== 'text' && curElement.children[j].hasAttribute('stroke') && curElement.children[j].getAttribute('stroke') !== 'null') {
                  curElement.children[j].setAttribute('stroke', '#000000')
                }
              }

              document.getElementById('screenshot').parentNode.removeChild(document.getElementById('screenshot'))
            })
            curElement.addEventListener('mousemove', (e) => {
              document.getElementById('screenshot').style.top = (e.offsetY + yOffset) + 'px'
              document.getElementById('screenshot').style.left = (e.offsetX + xOffset) + 'px'
            })
          }
        }
      }
    }
  },
  watch: {
    isShow (val) {
      this.$emit('update:stationSvgModal', val)
    },
    stationSvgModal (val) {
      this.isShow = val
      if (val) {
        this.defaultTab = 'tab0'
        this.svgList = []
        this.svgId = newGuid()
        this.initPageData()
      }
    }
  }
}
</script>
