<template>
  <div>
    <div class="center-block-date">
      <Select v-model="queryModel.startDate" style="width:100px" size="small" @on-change="handleChangeDate">
        <Option v-for="(date,index) in startDateArray" :key="index" :value="date.key">{{date.value}}</Option>
      </Select>
      <span class="m-l-5 m-r-5">至</span>
      <Select v-model="queryModel.endDate" style="width:100px" size="small" @on-change="handleChangeDate">
        <Option v-for="(date,index) in endDateArray" :key="index" :value="date.key">{{date.value}}</Option>
      </Select>
    </div>

    <!-- 显示地铁线路图 -->
    <SvgLine v-if="showMapType===1" ref="svgline" @map-finished="handleMapLoadFinished"/>
    <GeoMap v-else ref="geoMap" @map-finished="handleMapLoadFinished"/>

    <!-- 显示站点运维统计 -->
    <SvgStationDataV ref="svgStationDataV" :stationSvgModal.sync="stationSvgModal" :stationId="chooseStationId" :svgShowType="4"
      @svg-finished="svgFinished"/>
  </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import GeoMap from '@/components/map/GeoMap'
import SvgStationDataV from '@/components/svg/SvgStationDataV'

import { getStartDateList, getEndDateList } from '@/api/dw/time'
import { countMaxDayNumberGbResource } from '@/api/dw/maintain'

import { listCompanyAssets } from '@/api/dw/datav'

export default {
  components: {
    SvgLine, GeoMap, SvgStationDataV
  },
  data () {
    return {
      showMapType: this.$store.getters.token.publisherSetting.mediaType,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      loginCompanyId: this.$store.getters.token.userInfo.companyId,
      mapFinished: false,
      startDateArray: [],
      endDateArray: [],

      queryModel: {
        endDate: '',
        startDate: ''
      },

      stationSvgModal: false, // 弹窗显示SVG站点地图
      chooseStationId: 0,

      allAssetIds: []
    }
  },
  created () {
    this.queryModel.startDate = this.searchSchedule.startDate
    this.queryModel.endDate = this.searchSchedule.endDate
    this.initPageData()
  },
  methods: {
    initPageData () {
      const postData = { publisherId: this.publisherId }
      getStartDateList(postData).then(res => {
        this.startDateArray = res
      })
      getEndDateList(postData).then(res => {
        this.endDateArray = res
      })
    },
    handleChangeDate () {
      const schedule = {
        startDate: this.queryModel.startDate,
        endDate: this.queryModel.endDate
      }

      this.$store.commit('set_situation_companyId', (this.loginCompanyId === this.publisherId) ? '0' : this.loginCompanyId)
      this.$store.commit('set_situation_assetId', '0')

      this.$store.commit('set_situation_searchSchedule', schedule)
    },
    handleMapLoadFinished (val) {
      this.mapFinished = val

      if (val) {
        // 执行线路隐藏操作
        listCompanyAssets({ publisherId: this.publisherId }).then(res => {
          const assetIds = []
          let relateIds = []
          res.forEach(item => {
            relateIds = item.relateIds.split(',').map(x => parseInt(x))

            relateIds.forEach(element => {
              if (!assetIds.includes(element)) {
                assetIds.push(element)
              }
            })
          })

          this.allAssetIds = assetIds
          this.$refs.svgline.hideOtherAsset(assetIds)
        })
      }
    },
    LoadMapData () {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        if (this.showMapType === 1) {
          const showAssetIds = (this.assetId === '0') ? this.allAssetIds : this.mapShowAssetIds

          this.$refs.svgline.hideOtherAsset(showAssetIds)
        }
      } else {
        setTimeout(() => {
          this.LoadMapData()
        }, 1000)
      }
    },
    svgFinished () { // SVG站点地图加载完毕
      countMaxDayNumberGbResource({ stationId: this.chooseStationId }).then(res => {
        this.$refs.svgStationDataV.tagResource(res)
        this.$refs.svgStationDataV.choseSpinShow()
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    assetId () {
      return this.$store.state.situationWeb.assetId
    },
    mapShowAssetIds () {
      return this.$store.state.situationWeb.mapShowAssetIds
    },
    beignUpdateBottomAndRight () {
      return this.$store.state.situationWeb.beignUpdateBottomAndRight
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    },
    mapStations () {
      return this.$store.state.situationWeb.mapStations
    },
    beignUpdateMap () {
      return this.$store.state.situationWeb.beignUpdateMap
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    }
  },
  watch: {
    beignUpdateBottomAndRight () {
      this.LoadMapData()
    },
    beignUpdateMap () {
      // 仅限有路面后，在地图中全部标出点位
      if (this.showMapType === 2) {
        const showStations = this.mapStations.map((item) => {
          return {
            longitude: item.lng,
            latitude: item.lat,
            stationName: item.stationName,
            stationId: item.stationId
          }
        })

        this.$refs.geoMap.loadResourceStations(showStations)
      }
    },
    gbId (val) {
      if (val) {
        this.chooseStationId = val
        this.stationSvgModal = true
      }
    }
  }
}
</script>
